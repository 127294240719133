var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-info-container" },
    [
      _c("Form", {
        ref: "form",
        attrs: {
          form: _vm.form,
          "wrapper-col": { span: 21 },
          "label-col": { span: 3 },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }